import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { Layout } from './Layout';
import markdownRenderer from './MarkdownRenderer';
import Section from './Section';
import TextLibrary from './TextLibrary';
import { useTranslation } from 'react-i18next';

export enum LegalStuffType {
  PRIVACY_POLICY = 'privacyPolicy',
  APP_TERMS = 'appTerms',
  WEB_TERMS = 'webTerms',
}

interface LegalStuffInterface {
  type: LegalStuffType;
}

const LegalStuff = ({ type }: LegalStuffInterface) => {
  const { t } = useTranslation('legalStuff');

  let id;
  let title;
  let body;
  let metaTagTitle = '';
  let metaTagDescription = '';

  switch (type) {
    case LegalStuffType.PRIVACY_POLICY:
      id = 'privacyPolicy.id';
      title = t('privacyPolicyTitle');
      body = t('privacyPolicyTermsConditions');
      metaTagTitle = t('privacyPolicyMetaTagTitle');
      metaTagDescription = t('privacyPolicyMetaTagDescription');
      break;
    case LegalStuffType.APP_TERMS:
      id = 'appTerms.id';
      title = t('appTermsTitle');
      body = t('appTermsTermsConditions');
      metaTagTitle = t('appTermsMetaTagTitle');
      metaTagDescription = t('appTermsMetaTagDescription');
      break;
    default:
      id = 'webTerms.id';
      title = t('webTermsTitle');
      body = t('webTermsTermsConditions');
      metaTagTitle = t('webTermsMetaTagTitle');
      metaTagDescription = t('webTermsMetaTagDescription');
      break;
  }

  return (
    <Layout
      title={metaTagTitle}
      description={metaTagDescription}
      colorTheme="light"
    >
      <Section.Container backgroundColor="light" id={id} mt={[5, 6]}>
        <TextLibrary.H1>{title}</TextLibrary.H1>
        <TextLibrary.Paragraph as="div" color="dark">
          <ReactMarkdown components={markdownRenderer}>{body}</ReactMarkdown>
        </TextLibrary.Paragraph>
      </Section.Container>
    </Layout>
  );
};

LegalStuff.propTypes = {
  type: PropTypes.string.isRequired,
};

export default LegalStuff;
