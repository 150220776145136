import React from 'react';
import ContextProvider from '../components/ContextProvider';
import LegalStuff, { LegalStuffType } from '../components/LegalStuff';

const PrivacyPolicy = ({ localeCode = 'en-US' }: { localeCode?: string }) => (
  <ContextProvider localeCode={localeCode}>
    <LegalStuff type={LegalStuffType.PRIVACY_POLICY} />
  </ContextProvider>
);

export default PrivacyPolicy;
